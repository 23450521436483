import React, { Component } from 'react';
import './SectionHeader.scss';

class SectionHeader extends Component {

    render() {
        return (
            <header>
                <h1>Achtergrond</h1>
                <h3>Wie ik ben</h3>
            </header>
        )
    }
}

export default SectionHeader;