import React, { Component } from 'react';
import './Logo.scss';

class ButtonAutoScroll extends Component {

    handleRef(e) {
        e.preventDefault();
        document.querySelector('#header').scrollIntoView({
            behavior: 'smooth'
        });
    }

    render() {
        return (
            <a href="#header" className="logo" onClick={this.handleRed}>Drumschool Piet Sap</a>
        );
    }
}

export default ButtonAutoScroll;
