import React, { Component } from 'react';
import './ButtonAutoScroll.scss';

class ButtonAutoScroll extends Component {
    constructor(props) {
        super(props);
        this.handleRef = this.handleRef.bind(this);
    }

    handleRef(e) {
        e.preventDefault();
        document.querySelector(this.props.href).scrollIntoView({
            behavior: 'smooth'
        });
    }

    render() {
        return (
            <a className="auto-scroll-btn" href={this.props.href} onClick={this.handleRef}>
                <h3>Muziek maken? Kijk verder!</h3>
                <i className="fas fa-angle-down"></i>
            </a>
        );
    }
}

export default ButtonAutoScroll;
