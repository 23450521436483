import React, { Component } from 'react';
import './Header.scss';
import ButtonAutoScroll from './ButtonAutoScroll';

class Header extends Component {
  render() {
    return (
      <section id="header">
        <header>
          <h1 className="animate-2 title">Piet Sap</h1>
          <h2>Professioneel Drummer/Docent</h2>
        </header>

        <div className="animate-2 img"></div>

        <ButtonAutoScroll href="#bio"/>
      </section>
    );
  }
}

export default Header;
