import React, { Component } from 'react';
import './Career.scss';

class Career extends Component {
    constructor(props) {
        super(props);

        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(e) {
        let el = e.currentTarget;
        let containsHoverClass = el.className.includes("hoverClass");
        for (let child of el.parentNode.children) {
            child.className = child.className.replace(" hoverClass", "");
        }

        if (!containsHoverClass) {
            el.className = el.className + " hoverClass";
        }
    }

    render() {
        return (
            <section id="career">
                <div className="animate-3" onClick={this.handleClick}>
                    <img src="./assets/img/bio-1.jpg" alt="" />
                    <div className="animate-3 overlay">
                        <h2>Lee Towers</h2>
                        
                    </div>
                </div>
                <div className="animate-3"  onClick={this.handleClick}>
                    <img src="./assets/img/bio-2.jpg" alt="" />
                    <div className="animate-3 overlay">
                        <h2>The Mounties</h2>
                        
                    </div>          </div>
                <div className="animate-3"  onClick={this.handleClick}>
                    <img src="./assets/img/bio-3.jpg" alt="" />
                    <div className="animate-3 overlay">
                        <h2>Mini & Maxi</h2>
                        
                    </div>          </div>
                <div className="animate-3"  onClick={this.handleClick}>
                    <img src="./assets/img/bio-4.jpg" alt="" />
                    <div className="animate-3 overlay">
                        <h2>John Woodhouse</h2>
                        
                    </div>          </div>
                <div className="animate-3"  onClick={this.handleClick}>
                    <img src="./assets/img/bio-5.jpg" alt="" />
                    <div className="animate-3 overlay">
                        <h2>Ronnie Tober</h2>
                        
                    </div>          </div>
                <div className="animate-3"  onClick={this.handleClick}>
                    <img src="./assets/img/bio-6.jpg" alt="" />
                    <div className="animate-3 overlay">
                        <h2>Henk Elsink</h2>
                        
                    </div>          </div>
            </section>
        );
    }
}

export default Career;
