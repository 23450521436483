import React, { Component } from 'react';
import './ButtonSidenav.scss';

class ButtonSidenav extends Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    this.props.onClick();
  }

  render() {
    return (
      <button onClick={this.handleClick} id="sidenav-btn" className="animate-2"><i className="fas fa-bars"></i></button>
    );
  }
}

export default ButtonSidenav;
