import React, { Component } from 'react';
import './Contact.scss';

class Contact extends Component {
    render() {
        return (
            <section id="contact">
                <header>
                    <h2>Interesse? Neem contact op!</h2>
                    <p>Voor drumles, orkest en andere drumzaken...</p>
                </header>

                <div className="contact-options">
                    <div>
                        <i className="fas fa-phone"></i>
                        <a href="tel:+31655180438"><h3>+31 6 5518 0438</h3></a>
                    </div>
                    <div>
                        <i className="fas fa-at"></i>
                        <a href="mailto:info@drumschoolpietsap.nl"><h3>info@drumschoolpietsap.nl</h3></a>
                    </div>
                </div>
            </section>
        );
    }
}

export default Contact;