import React, { Component } from 'react';
import './Lessons.scss';

class Lessons extends Component {

  handleClick(e) {
    let el = e.currentTarget;
    let containsHoverClass = el.className.includes("hoverClass");
    for (let child of el.parentNode.children) {
        child.className = child.className.replace(" hoverClass", "");
    }

    if (!containsHoverClass) {
        el.className = el.className + " hoverClass";
    }
  }

  render() {
    return (
      <section id="lessons">
        <header>
          <h1>Drumles</h1>
          <h3>Wat ik bied</h3>
        </header>

        <div className="aspect-catalogue">
          <div className="animate-2 pupil1" onClick={this.handleClick}>
            <i className="fas fa-drum animate-2"></i>
            <h3>Drummen & techniek</h3>
            <p>Waaronder muziek lezen</p>
          </div>
          <div className="animate-2 pupil2" onClick={this.handleClick}>
            <i className="fas fa-clock animate-2"></i>
            <h3>30 min per week</h3>
            <p>15 min drummen, 15 min techniek</p>
          </div>
          <div className="animate-2 pupil3" onClick={this.handleClick}>
            <i className="fas fa-book-open animate-2"></i>
            <h3>Eigen compositie schrijven</h3>
            <p>Bijvoorbeeld voor een drumsolo</p>
          </div>
          <div className="animate-2 pupil4" onClick={this.handleClick}>
            <i className="fas fa-chalkboard-teacher animate-2"></i>
            <h3>2 gratis proeflessen</h3>
            <p>Op een echt drumstel</p>
          </div>
        </div>
      </section>
    );
  }
}

export default Lessons;
