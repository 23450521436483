import React, { Component } from 'react';
import './Sidenav.scss';

class Sidenav extends Component {
  constructor(props) {
    super(props);
    this.handleRef = this.handleRef.bind(this);
  }

  handleRef(e, href) {
    e.preventDefault();
    document.querySelector(href).scrollIntoView({
        behavior: 'smooth'
    });
    this.props.onRef();
  }

  render() {
    return (
      <nav id="sidenav" className={this.props.sidenavOpened ? 'animate-3 opened' : 'animate-3'}>
        <a className="animate-2" href="#bio" onClick={(e => this.handleRef(e, '#bio'))}>Achtergrond</a>
        <a className="animate-2" href="#bio" onClick={(e => this.handleRef(e, '#career'))}>Carrière</a>
        <a className="animate-2" href="#lessons" onClick={(e => this.handleRef(e, '#lessons'))}>Drumles</a>
        <a className="animate-2" href="http://deltaswingband.nl/">Orkest</a>
        <a className="animate-2" href="#locations" onClick={(e => this.handleRef(e, '#locations'))}>Locaties</a>
        <a className="animate-2" href="#contact" onClick={(e => this.handleRef(e, '#contact'))}>Contact</a>
      </nav>
    );
  }
}

export default Sidenav;
