import React, { Component } from 'react';
import './../styles.scss';

import Header from './header/Header';
import Bio from './bio/Bio';
import Career from './career/Career';
import Lessons from './lessons/Lessons';
import Locations from './locations/Locations';
import Contact from './contact/Contact';

import ButtonSidenav from './sidenav/ButtonSidenav';
import Sidenav from './sidenav/Sidenav';
import Logo from './logo/Logo';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sidenavOpened: false
    }

    this.handleSidenavToggle = this.handleSidenavToggle.bind(this);
  }

  handleSidenavToggle() {
    this.setState((state) => {
      return {sidenavOpened: !this.state.sidenavOpened};
    })
  }

  render() {
    return (
      <div id="app">
        <Logo/>
        <ButtonSidenav onClick={this.handleSidenavToggle}/>
        <Sidenav sidenavOpened={this.state.sidenavOpened} onRef={this.handleSidenavToggle}/>
        
        <Header/>
        <Bio/>
        <Career/>
        <Lessons/>
        <Locations/>
        <Contact/>
      </div>
    );
  }
}

export default App;
