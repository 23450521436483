import React, { Component } from 'react';
import './Locations.scss';

class Locations extends Component {
  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(e) {
    let el = e.currentTarget;
    let containsHoverClass = el.className.includes("hoverClass");
    for (let child of el.parentNode.children) {
        child.className = child.className.replace(" hoverClass", "");
    }

    if (!containsHoverClass) {
        el.className = el.className + " hoverClass";
    }
  }

  render() {
    return (
      <section id="locations">
        {/* <header>
          <h1>Locaties</h1>
          <h3>Waar ik ben</h3>
        </header> */}

        <div className="location-catalogue">
          <div className="location school animate-2" onClick={this.handleClick}>
            <div className="overlay animate-2">
            </div>
            <h2 className="animate-2">Op school</h2>
            <p className="animate-2">Muziekschool de Suite in Roosendaal <br/> <a href="http://www.schoolvoordekunstenroosendaal.nl">Klik hier voor de website!</a></p>
          </div>
          <div className="location at-home animate-2" onClick={this.handleClick}>
            <div className="overlay animate-2">
            </div>
            <h2 className="animate-2">Op locatie</h2>
            <p className="animate-2">Privé lessen thuis</p>
          </div>
        </div>
      </section>
    );
  }
}

export default Locations;
