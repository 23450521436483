import React, { Component } from 'react';
import './Bio.scss';
import SectionHeader from './../section-header/SectionHeader';

class Bio extends Component {
  render() {
    return (
      <section id="bio">
        <SectionHeader/>

        <div className="img-container"><img src="./assets/img/IMG_1066.jpeg" alt="" /></div>
        <div className="bio-text">
          <p>Ik ben afgestudeerd als Uitvoerend Drummer en Percusionist aan het Rotterdams Conservatorium in 1965 waarna ik als <span>professioneel drummer</span> aan de slag ben gegaan. De eerste 2 jaar als onderdeel van mijn dienstplicht. In deze periode heb ik in de Geniekapel der Koninklijke Landmacht gespeeld.</p>
          <p>In 1967 kreeg ik een aanbieding om met de bekende conferencier Henk Elsink op tournee te gaan langs de Nederlandse theaters. Dit is het begin geweest van een lange periode als professioneel theater drummer. In deze tijd heb ik <span>vele bekende artiesten</span> begeleid zoals Ronnie Tober, Mini&Maxi, The Mounties en John Woodhouse. Ook ben ik op wereld tournee geweest met de Engelse band Point West, waarbij wij op plaatsen zoals Lissabon, New York, Miami, Caracas en Hawai hebben gespeeld. Terug in Nederland ben ik in de begeleiding band gekomen van <span>Lee Towers</span> (samen met andere bekende Nederlandse muzikanten zoals Huug Rosman en Aad Klaris), waarbij ik ook regelmatig heb gespeeld voor de Nederlandse toeristen in Spanje.</p>
          <p>Sinds 4 jaar ben ik actief als drumleraar bij Muziekschool de Suite in Roosendaal of als privé leraar bij studenten thuis.</p>
        </div>
      </section>
    );
  }
}

export default Bio;
